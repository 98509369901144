<template>
  <q-menu
    v-model="changeFulfillmentMenu"
    content-class="hldropdown"
    anchor="bottom middle"
    self="top middle"
    :offset="$q.screen.lt.md ? [100, 15] : [100, 35]"
  >
    <!-- <transition
      appear
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    > -->
    <!-- <div class="hldropdown" v-show="popOverState"> -->
    <q-no-ssr>
      <LocationDialogContent />
    </q-no-ssr>
    <!-- </div> -->
    <!-- </transition> -->
  </q-menu>
</template>

<script>
import LocationDialogContent from './LocationDialogContent'
export default {
  components: { LocationDialogContent },
  data() {
    return {
      changeFulfillmentMenu: false,
    }
  },
  computed: {
    // popOverState: {
    //   get() {
    //     return this.getDialogState('location')
    //   },
    //   set(val) {
    //     console.log(val)
    //     this.changeDialogState({
    //       dialog: 'location',
    //       val: !!val,
    //     })
    //   },
    // },
  },
  mounted() {
    if (this.$route.name == 'Home') {
      this.changeFulfillmentMenu = true
      // this.changeDialogState({
      //   dialog: 'location',
      //   val: true,
      // })

      setTimeout(() => {
        this.changeFulfillmentMenu = false
        // this.changeDialogState({
        //   dialog: 'location',
        //   val: false,
        // })
      }, this.hideLocationPopup)
    }
  },
}
</script>
<style lang="scss">
.hldropdown {
  // position: absolute;
  // left: 50%;
  // top: 50px;
  // transform: translateX(-50%);
  padding: 15px;
  /* top: 85px !important; */
  // background: #fff;
  background: $primary;
  background: var(--q-color-primary) !important;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  /* z-index: 2; */
  transition: all 0.3s ease-in-out;
  overflow: visible;
  &:before {
    content: '';
    position: absolute;
    top: -15px;
    left: 50%;
    width: 15px;
    height: 15px;
    margin-left: -7.5px;
    // border-bottom: 10px solid #fff;
    border-bottom: 10px solid $primary;
    border-bottom: 10px solid var(--q-color-primary);
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
  }
  @media screen and (max-width: 1023px) {
    /* top: 180px !important;
    left: 15px !important; */
  }
}
</style>
